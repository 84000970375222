@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body {

    margin: 0;
    font-family: 'Poppins', sans-serif;
}

body *{

    box-sizing: border-box;
}

.main-login {
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

}

.left-login {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.left-login>h1 {
    color: #1e3b5a;

}

.left-login-img{
width: 35vw;


}

.right-login {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.card-login {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 35px;
    background-color: #dae1e7;
    border-radius: 20px;
    /* box-shadow: 0px 10px 40px #1e3b5a; */

}

.card-login>h1 {
    color: #1e3b5a;
    font-weight: 800;
    margin: 0;


}

.textfield {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0px;


}

.textfield>input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 15px;
    background: darkgray;
    color: #1e3b5a;
    font-size: 12pt;
    box-shadow: 0px 10px 40px #fffefe;
    outline: none;
    box-sizing: border-box;

}

.textfield>label {
    color: #1e3b5a;
    margin-bottom: 10px;

}


.textfield> input::placeholder{

color: rgb(62, 63, 103);

}


.btn-salvar{
 width: 100%;
padding: 16px 0px;
margin: 25px;
border: none;
border-radius: 8px;
outline: none;
text-transform: uppercase;
font-weight: 800;
letter-spacing: 3px;
background-color: #1e3b5a;
color: #fffefe;
cursor:pointer;
box-shadow: 0px 10px 40px  -12px #fffefe ;




}

@media only screen and (max-width: 950px) {
.card-login{
width: 85%;

}


    
}
@media only screen and (max-width: 950px) {

.main-login{
    flex-direction:column ;
}

.left-login > h1{
    display: none;


}
.left-login{
    width: 100%;
    height: auto;
}

.right-login{
    width: 100%;
    height: auto;

}

.left-login:image {
width: 50vh;


}

.left-login{

    width:90%;
}

}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap');
@import url("//fonts.googleapis.com/css?family=Muli:300");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

/*** Mixins & Default Styles ***/
/* @import "https://designmodo.github.io/Flat-UI/dist/css/flat-ui.min.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css";
@import "https://daneden.github.io/animate.css/animate.min.css"; */
/*-------------------------------*/
/*           VARIABLES           */
/*-------------------------------*/

/* Animations */

body{
  overflow-x: hidden;
  
}

.wobble-hor-bottom {
	-webkit-animation: wobble-hor-bottom 1.5s infinite ;
  animation: wobble-hor-bottom 1.5s infinite ;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-15 8:49:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}

.title-button{
  font-weight: bold;
  padding-bottom: 20px;
}

.action-button {
  display: block;
  position: fixed;
  width: 4em;
  height: 4em;
  z-index: 10;
  bottom: 1em;
  right: 1em;
  color: #fff;
  background-image: url('../img/icon-faq.png');
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0.1em 0 0.25em rgba(0, 0, 0, 0.15), 0.2em 0 1em rgba(0, 0, 0, 0.2);
}
.action-button::after {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.action-button-content {
  background-color: white;
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: 15;
  bottom: 2em;
  right: 2em;
  transform-origin: bottom right;
  transition: box-shadow 50ms ease-out, border-radius 50ms ease-out 25ms, width 90ms ease-in, height 60ms ease-in;
  overflow: hidden;
}
.action-button-content-inner {
  opacity: 0;
  transition: opacity 30ms ease-out 0;
  padding: 28px;
}
.action-button-content-inner h2 {
  color: black;
  margin: 0 0 1em 0;
  padding: 0;
}
/* .action-button-content-inner .btn {
  display: block;
  padding: 10px 0;
  text-align: center;
  color: #0cebeb;
  border: 1px solid #0cebeb;
  border-radius: 3px;
  margin-bottom: 1.5em;
  transition: color 50ms linear, background 50ms linear;
  text-decoration: none;
} */

.action-button-content-inner .content-btn {
  display: block;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 1.5em;
  color:#000;
}
/* .action-button-content-inner .btn:hover {
  color: white;
  background: #0cebeb;
} */
/* .action-button-content-inner::after {
  content: "⌟";
  position: absolute;
  font-size: 25px;
  top: 5px;
  right: 15px;
  color: #666;
  pointer-events: auto;
  transform: rotate(45deg);
  cursor: pointer;
} */

.box{
  width: 200px;
  height: 200px;
  background-color: red;

}
.actionsBoxOpen .action-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.5em;
  height: 1.5em;
  padding: 0.25em;
  transform-origin: 50%;
  transform: translate(-50%, -50%);
  font-size: 200%;
  font-weight: 100;
  line-height: 0.95em;
  text-align: center;
  border-radius: 50%;
  transition: all 400ms ease-out;
}

.actionsBoxClosed .actionsBoxClosed::after {
  content: "";
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  transform-origin: 50%;
  transform: translate(-50%, -50%);
  font-size: 200%;
  font-weight: 100;
  line-height: 0.95em;
  text-align: center;
  border-radius: 50%;
  transition: all 400ms ease-out !important;
}
.actionsBoxOpen .action-button-content {
  /* pointer-events: none; */
  position: fixed;
  width: 400px;
  height: 351px;
  cursor: default;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
  border-radius: 0;
  bottom: 2em;
  right: 2em;
  transform-origin: bottom right;
  transition: box-shadow 50ms ease-in 100ms, border-radius 50ms ease-in 100ms, width 150ms ease-in, height 150ms ease-in;
}
.actionsBoxOpen .action-button-content-inner {
  opacity: 1;
  transition: opacity 100ms ease-out 200ms;
}
.actionsBoxOpen .action-button-content-inner .btn {
  pointer-events: auto;
}

.nav .open > a {
  background-color: transparent;
}
.nav .open > a:hover {
  background-color: transparent;
}
.nav .open > a:focus {
  background-color: transparent;
}
/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/
#wrapper {
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding-left: 0;
  transition: all 0.5s ease;
}
#wrapper.toggled {
  padding-left: 220px;
}
#wrapper.toggled #sidebar-wrapper {
  width: 220px;
}
#wrapper.toggled #page-content-wrapper {
  margin-right: -220px;
  position: absolute;
}
#sidebar-wrapper {
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  background: #1a1a1a;
  height: 100%;
  left: 220px;
  margin-left: -220px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s ease;
  width: 0;
  z-index: 1000;
}

.planos-img img{
  width: 100%;
}

.pl-lg-0{
  padding-left:inherit;
}

.pl-md-0{
  padding-left: inherit;
}

.pl-sm-0{
  padding-left: inherit;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.btn-fale--conosco{
  background-color: #edf0f1 !important;
}

.btn-fale--conosco:hover{
  color: rgba(188,27,27,1) !important;
}

.capa-site{
  background-image: url('../img/capa-site-2.png');
  background-size: cover;
}
.bar-gray{
  background-color:#EEEE;
  width:100%;
  height:2px;
}

.no-bold{
  font-weight: normal !important;
}
.img-sobre img{
    width: 45px;
}



.montserrat{
  font-family: 'Montserrat', sans-serif;
}

.borda{
  border-bottom: solid 2px #EEEE
}

.duvidas-text h2{
  font-weight: 700;
  text-align: left;
  line-height: 60px;
  
}
#page-content-wrapper {
  padding-top: 70px;
  width: 100%;
}
/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
.sidebar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 220px;
}
.sidebar-nav li {
  display: inline-block;
  line-height: 20px;
  position: relative;
  width: 100%;
}
.sidebar-nav li:before {
  background-color: #1c1c1c;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.2s ease-in;
  width: 3px;
  z-index: -1;
}
.sidebar-nav li:first-child a {
  background-color: #1a1a1a;
  color: #ffffff;
}
.sidebar-nav li:nth-child(2):before {
  background-color: #402d5c;
}
.sidebar-nav li:nth-child(3):before {
  background-color: #4c366d;
}
.sidebar-nav li:nth-child(4):before {
  background-color: #583e7e;
}
.sidebar-nav li:nth-child(5):before {
  background-color: #64468f;
}
.sidebar-nav li:nth-child(6):before {
  background-color: #704fa0;
}
.sidebar-nav li:nth-child(7):before {
  background-color: #7c5aae;
}
.sidebar-nav li:nth-child(8):before {
  background-color: #8a6cb6;
}
.sidebar-nav li:nth-child(9):before {
  background-color: #987dbf;
}
.sidebar-nav li:hover:before {
  transition: width 0.2s ease-in;
  width: 100%;
}
.sidebar-nav li a {
  color: #dddddd;
  display: block;
  padding: 10px 15px 10px 30px;
  text-decoration: none;
}
.sidebar-nav li.open:hover before {
  transition: width 0.2s ease-in;
  width: 100%;
}
.sidebar-nav .dropdown-menu {
  background-color: #222222;
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
}
.sidebar-nav > .sidebar-brand {
  font-size: 20px;
  height: 65px;
  line-height: 44px;
}
/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/
.hamburger {
  background: transparent;
  border: none;
  display: block;
  height: 32px;
  margin-left: 15px;
  position: fixed;
  top: 20px;
  width: 32px;
  z-index: 999;
}
.hamburger:hover {
  outline: none;
}
.hamburger:focus {
  outline: none;
}
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  /* -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out; */
  color: #ffffff;
  content: "";
  display: block;
  font-size: 14px;
  line-height: 32px;
  opacity: 0;
  text-align: center;
  width: 100px;
}
.hamburger.is-closed:hover before {
  /* -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out; */
  display: block;
  opacity: 1;
}
.hamburger.is-closed:hover .hamb-top {
  /* -webkit-transition: all 0.35s ease-in-out; */
  top: 0;
}
.hamburger.is-closed:hover .hamb-bottom {
  /* -webkit-transition: all 0.35s ease-in-out; */
  bottom: 0;
}
.hamburger.is-closed .hamb-top {
  /* -webkit-transition: all 0.35s ease-in-out; */
  background-color: rgba(255, 255, 255, 0.7);
  top: 5px;
}
.hamburger.is-closed .hamb-middle {
  background-color: rgba(255, 255, 255, 0.7);
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-closed .hamb-bottom {
  /* -webkit-transition: all 0.35s ease-in-out; */
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 5px;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.hamburger.is-open .hamb-top {
  /* -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08); */
  background-color: #fff;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-open .hamb-middle {
  background-color: #fff;
  display: none;
}
.hamburger.is-open .hamb-bottom {
  /* -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08); */
  background-color: #fff;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-open:before {
  /* -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out; */
  color: #ffffff;
  content: "";
  display: block;
  font-size: 14px;
  line-height: 32px;
  opacity: 0;
  text-align: center;
  width: 100px;
}
.hamburger.is-open:hover before {
  /* -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out; */
  display: block;
  opacity: 1;
}
/*-------------------------------*/
/*          Dark Overlay         */
/*-------------------------------*/
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
/* SOME DEMO STYLES - NOT REQUIRED */






* {
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.type{
  text-transform: uppercase;
}
.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.m-rl-40{
  margin: 0px 40px;
}

.redes-sociais a {
  color:#FFF;
  padding-right: 8px;
}

.redes-sociais a{
  font-size:20px;
}

.termos-link a {
  color:#FFF;

}

/* .img-card--planos{
  width: 45px;
  transition: .2s linear;
} */

.fadeIn{
  opacity:1;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
  width:45px;
}

.f-inter a{
  font-family: 'Inter', sans-serif !important;
  font-size: 15px;
}

.bar-blue{
  background-color:#1e3b5a;
  width:100%;
  height:3px;
}

.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.1s, height 0.1s, opacity 0.1s 0.1s;
}

.icon-card-planos{
    width:35px;
    margin-right: 20px;
}


.plano-img{
  max-width:45px;
}
.pricing-table{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: min(1600px, 100%);
  margin: auto;
}

.pricing-table li {
  list-style: none;
}

.pl-1{
  padding-left: 3em !important;
}

.pl-2 {
  padding-left: 4em !important;
}

.pl-3{
  padding-left: 5em !important;
}

.pricing-card{
  flex: 1;
  position: relative;
  max-width: 360px;
  background-color: #fff;
  /* margin: 20px 10px; */
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #2d2d2d;
  transition: .3s linear;
}

.pricing-card-header{
  /* position: fixed; */
  background-color: #1d3c5e;
  display: inline-block;
  color: #fff;
  padding: 12px 30px;
  border-radius: 0 0 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: .4s linear;
}

.pricing-card:hover .pricing-card-header{
  box-shadow: 0 0 0 26em #f6f8f9;
  
  
}

.price{
  font-size: 70px;
  color: #1d3c5e;
  margin: 40px 0;
  transition: .2s linear;
}

.price sup, .price span{
  font-size: 22px;
  font-weight: 700;
}

/* .pricing-card:hover ,.pricing-card:hover .price{
  color: #fff;
} */

.pricing-card li{
  font-size: 16px;
  padding: 10px 0;
  text-transform: uppercase;
}

.order-btn{
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 80px;
  border: 2px solid #ba1f1f;
  color: #ba1f1f;
  padding: 18px 40px;
  border-radius: 8px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  transition: .3s linear;
}

.order-btn:hover{
  border-color: #1d3c5e;
  background-color: #1d3c5e;
  color: #fff;
}

@media screen and (max-width:1100px){
  .pricing-card{
    flex: 50%;
  }
}


.profile {
  width: 100%;
  height: 240px;
  font-family: "Muli", sans-serif;
  color: #e0f7fa;
}
.profile .profile-pic {
  width: 200px;
  height: 200px;
  float: left;
  border-radius: 50% 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.profile .text-container-profile {
  color: #000;
  /* width: 360px; */
  padding-left: 10px;
  /* margin-left: 256px; */
  /* padding-top: 68.5714285714px; */
  /* text-align: center; */
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.profile h1 {
  margin-bottom: 34.2857142857px;
  font-weight: 400;
  font-size: 2.8em;
  line-height: 1em;
  letter-spacing: -1px;
  text-transform: uppercase;
}
.profile .title {
  font-size: 0.8em;
  letter-spacing: 1px;
}

.fs-10{
  font-size: 10px !important;
}

.fs-11{
  font-size: 11px !important;
}

.fs-12{
  font-size: 12px !important;
}

.fs-13{
  font-size: 13px !important;
}

.fs-14{
  font-size: 14px !important;
}

.fs-15{
  font-size: 15px !important;
}

.fs-16{
  font-size: 16px !important;
}

.fs-17{
  font-size: 17px !important;
}

.fs-18{
  font-size: 18px !important;
}

.fs-20{
  font-size: 20px !important;
}

.fs-25{
  font-size: 25px !important;
}

.mt-6{
  margin-top:4rem !important;
}

.mt-7{
  margin-top: 5rem !important;

}

.mt-8{
  margin-top: 6rem !important;
}

.pt-6{
  padding-top: 4rem !important;
}

.pt-6{
  padding-top: 5rem !important;
}

.pt-7{
  padding-top: 6rem !important;
}

.link-home-desc{
  text-decoration: none;
  cursor: pointer;
}


.yama-button{
  color: #bc1b1b !important;
  background-color: #FFF;
  /* border: 2px solid #1d3c5e; */
  border: 1px solid #bc1b1b !important;
  padding: 5px;
  width: 200px;
  font-weight:bold;

}
.yamacorp-background{
  width:100%;
}

.header-logo{
  margin-left: 21px;
}

.wi-100{
  width: 100% !important;
}

.hi-100{
  height: 100%;
}

.ptr-2{
  padding-top: 2rem !important;
}

.pr-50{
  padding-right:50px !important;
}

.img-home-desc img{
  width:100%;
}

.img-home-desc{
  margin-right: 40px;
}

.yama-button:hover{
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 8%, rgba(21,63,115,1) 32%, rgba(188,27,27,1) 84%); */
  background-color:#1d3c5e !important;
  border-color: #1d3c5e !important;
  color:#FFF !important;
}
.teste{
  background-color: #000;
  border: none;
}

.bg-gray{
  background-color: #f6f8f9;
}

.ln-30{
  line-height: 30px;
}

.ln-20{
  line-height: 20px;
}

.ln-10{
  line-height: 10px;
}

.yama-titles{
  font-weight: 700;
  text-transform: uppercase;
}

.logo-card-yama{
  display:flex;
  align-items: center;
  width:100%;
  text-transform: uppercase;
}

.card-yama-content{
  font-size: 16px;
}

.logo-card-yama img{
    width:45px;
}

.yama-corp-title{
  color:#000;
  font-weight: 700;
  font-size: 50px;
}

.card-carousel-text span{
  font-size: 14px;
  margin-top: 5px;
}

.tela-yama{
  background-image: url('../img/tela_yama.jpg')
}

.yama-colo-red{
  color:#bc1b1b !important;
}

.div-carrousel{
  width:auto !important;
}

.imgBanner{
  height:100%;
  width:100% ;
  background-size: cover;
  background-image: url('../img/erptest.jpg');
  background-position: bottom
}
.yama-color-gray{
  color: #4b4848;
  font-weight: normal;
}

.desc-loja--text{
  font-size: 2.25rem;
  font-weight: bold;
  color: #000000;
}

.card-carousel-text{
  color:#000;
}

.active-menu{
  display: block !important;
}
  
 
i {
  margin-right: 10px;
}
/*----------bootstrap-navbar-css------------*/
.navbar-logo {
  padding: 15px;
  color: #fff;
}
.navbar-mainbg {
  background-color: #153f73;
  padding: 0px;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
}
#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
#navbarSupportedContent ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}
#navbarSupportedContent > ul > li.active > a {
  color: #153f73;
  background-color: transparent;
  transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}
.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
  cursor: pointer;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: rgb(181, 44, 44);
  bottom: 10px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #153f73;
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}
.navbar-header{
  box-shadow: 0 2px 10px rgb(0 0 0 / 25%);
}

@media (min-width: 992px) {
  .navbar-expand-custom {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  
  }
  .navbar-expand-custom .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 60vw;
    

  }
  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}



.p-10{
  padding: 10px !important;
}

.p-20{
  padding: 20px !important;
}

.p-30{
  padding: 30px !important;
}

.p-40{
  padding: 40px !important;
}

.p-50{
  padding: 50px !important;
}
#check:checked ~ ul {
  left: 0;
}

/* Carrousel */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&display=swap");
body {
  margin: 0;
  
}

.container-carrousel {
  height: 100vh;
}
@media screen and (min-width: 1026px) {
  .container {
    padding-top: 5rem;
  }
}

.carrousel {
  width: 90vw;
  max-width: 1440px;
  margin: auto;
}
@media screen and (max-width: 1025px) {
  .carrousel {
    max-width: 90vw;
  }
}
.carrousel__link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1025px) {
  .carrousel__link {
    max-width: 100vw;
    max-height: 50vh;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .carrousel__link {
    max-height: 100vh;
  }
}
@media screen and (max-width: 812px) and (orientation: landscape) {
  .carrousel__link {
    max-height: 100vh;
  }
}
@media screen and (min-width: 1026px) {
  .carrousel__image {
    width: 100%;
  }
}
@media screen and (max-width: 1025px) {
  .carrousel__image {
    max-height: 100vw;
  }
}
.carrousel__captions {
  position: absolute;
  right: 5%;
  max-width: 40%;
}
@media screen and (max-width: 1025px) {
  .carrousel__captions {
    left: 0;
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 1025px) {
  .carrousel__captions.captions-image img {
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .carrousel__captions.captions-image img {
    width: 80%;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .carrousel__captions.captions-image img {
    width: 100vh;
  }
}
@media screen and (max-width: 812px) and (orientation: landscape) {
  .carrousel__captions.captions-image img {
    width: 100vh;
  }
}

.captions-container {
  padding: 25px;
  border-radius: 0.5rem;
}
@media screen and (max-width: 1025px) {
  .captions-container {
    width: 50%;
    margin: auto;
  }
}
.captions-container__title {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  font-size: 3em;
}
@media screen and (max-width: 1025px) {
  .captions-container__title {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .captions-container__title {
    font-size: 1.25rem;
  }
}
.captions-container__subtitle {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  font-size: 1em;
}
@media screen and (max-width: 1025px) {
  .captions-container__subtitle {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .captions-container__subtitle {
    font-size: 1rem;
  }
}
.captions-container__text {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 0.9em;
  font-weight: 400;
  padding: 10px 0;
  line-height: 1.65em;
}
@media screen and (max-width: 1025px) {
  .captions-container__text {
    text-align: center;
  }
}

.slick-arrow {
  position: absolute;
  top: calc(50% - 20px);
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: transparent;
  border: none;
  z-index: 10;
}

.slick-prev {
  left: 20px;
  background: url(https://image.flaticon.com/icons/png/512/318/318477.png) no-repeat;
  background-size: contain;
  filter: invert(100%);
}

.slick-next {
  right: 20px;
  background: url(https://image.flaticon.com/icons/png/512/318/318476.png) no-repeat;
  background-size: contain;
  filter: invert(100%);
}



.footer{
    /* position:auto; */
    overflow: hidden;
}
.bar{
    border-bottom: 1px solid #FFF;
}

.logo-img{
    width:200px;
}

.logo-img-title{
  max-width:100%;
}

.endereco-texto{
    font-weight:none;
    color:#9e9e9e;
}
.desc-loja{
    font-family: 'Nunito',Helvetica,Arial,Lucida,sans-serif;
}

.footer-yama{
    background-color:#171717;    
    padding: 10px;
    font-size:12px;    
}

.footer-item-lista{
  padding: 8px 0px;
  
}

.footer-item-lista a {
  text-decoration: none;
  color:#FFF;
}

.footer-item-lista a:hover{
  color:#FFF;
  text-decoration: underline;
}

.footer-yama--copy{
    color: #9E9E9E;
    text-align: left;
}
.endereco{
    color:#FFF;
    font-weight:bold;
    font-family: 'Poppins', sans-serif;
}

.footer-yama--endereco{
    background: linear-gradient( to right, #153f73, #2b363a);
    padding:20px;
}

/* Media Querys */

@media (max-width: 320px) {
  .font-sobre{
    font-size: 14px !important;
  }
}

@media (max-width: 375px){
  .pl-sm-0{
    padding-left: 0 !important;
  }
  .pl-sm-1{
    padding-left: 15px !important;
  }

  .pr-sm-1{
    padding-right: 15px !important;
  }
}

